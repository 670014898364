var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isInitialToken && !_vm.loading)?_c('cp-table',{ref:"cpTable",attrs:{"without-search":"","filters":_vm.filters,"fields":_vm.fields,"get-data-action":"signatures/getTransactionSignaturesList","url-params":_vm.urlParams},on:{"onSort":function($event){return _vm.$emit('onSort', $event)},"tableDataUpdated":_vm.clearSelection},scopedSlots:_vm._u([{key:"HEAD_checkbox",fn:function(ref){
var rowData = ref.rowData;
return [(rowData)?_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allSelect),expression:"allSelect"}],staticClass:"custom-control-input",attrs:{"id":"select-all-cb-0","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allSelect)?_vm._i(_vm.allSelect,null)>-1:(_vm.allSelect)},on:{"click":_vm.onSelectAll,"change":function($event){var $$a=_vm.allSelect,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allSelect=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allSelect=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allSelect=$$c}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"select-all-cb-0"}})]):_vm._e()]}},{key:"checkbox",fn:function(ref){
var rowData = ref.rowData;
return [(!_vm.isSigned(rowData.item))?_c('b-form-checkbox',{on:{"change":function($event){return _vm.selectEntity(rowData.item)}},model:{value:(_vm.selectedEntity[rowData.item.id]),callback:function ($$v) {_vm.$set(_vm.selectedEntity, rowData.item.id, $$v)},expression:"selectedEntity[rowData.item.id]"}}):_vm._e()]}},{key:"type",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":"outline-info"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.typeLabels[rowData.item.type]))+" ")])]}},{key:"owner",fn:function(ref){
var rowData = ref.rowData;
return [_c('description',{attrs:{"description-text":rowData.item.operatorName}})]}},{key:"description",fn:function(ref){
var rowData = ref.rowData;
return [_c('description',{attrs:{"description-text":rowData.item.description}})]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{staticClass:"align-text-bottom ml-1",attrs:{"variant":_vm.txStatus.styles[rowData.item.status]}},[_vm._v(" "+_vm._s(_vm.$t(_vm.txStatus.labels[rowData.item.status]))+" ")])]}},{key:"date",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.date)))])]}},{key:"signaturesRequired",fn:function(ref){
var rowData = ref.rowData;
return [(_vm.isSigned(rowData.item) && rowData.item.signatures === 0)?_c('div',[_vm._v(" "+_vm._s(("1 / " + (rowData.item.threshold)))+" ")]):_c('div',[_vm._v(" "+_vm._s(((rowData.item.signatures) + " / " + (rowData.item.threshold)))+" ")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-dropdown',{attrs:{"right":"","variant":"default btn-xs icon-btn md-btn-flat hide-arrow"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"ion ion-ios-settings"})]),(!_vm.isSigned(rowData.item) && _vm.notFailure(rowData.item))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.signTransaction(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('signatures.option.menu.sign'))+" ")]):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){return _vm.viewTransaction(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('signatures.option.menu.view'))+" ")]),(!_vm.isSigned(rowData.item))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteTransaction(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('signatures.option.menu.remove'))+" ")]):_vm._e(),(!_vm.isSigned(rowData.item) && (rowData.item.signatures && rowData.item.signatures > 0))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.clearSignatures(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('signatures.option.menu.clearSignatures'))+" ")]):_vm._e(),(!_vm.isAlgorandNetwork && _vm.isSent(rowData.item))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.speedUpTransaction(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('signatures.option.menu.speedUp'))+" ")]):_vm._e(),(!_vm.isAlgorandNetwork && _vm.isSent(rowData.item))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.cancelTransaction(rowData.item)}}},[_vm._v(" "+_vm._s(_vm.$t('signatures.option.menu.cancelTransaction'))+" ")]):_vm._e()],2)]}}],null,false,3984727519)}):_c('div',[_c('b-card',[_c('div',{staticClass:"row align-items-center mb-2"},[_c('div',{staticClass:"col-md-12 text-center"},[(_vm.loading)?_c('b-spinner',{staticClass:"align-middle"}):(_vm.isInitialToken)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('signatures.label.tokenInitialStateMessage'))+" ")]):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }