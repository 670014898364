<template>
  <div
    v-if="getDescriptionWallet"
    class="d-inline"
  >
    <template v-for="(part, index) in splitDescription">
      <span :key="`a-${index}`">
        {{ part }}
      </span>
      <a
        v-if="index !== splitDescription.length - 1"
        :key="index"
        :href="getWalletUrl(getDescriptionWallet(index))"
        target="_blank"
      >
        {{ getDescriptionWallet(index) }}
      </a>
    </template>
  </div>
  <span v-else>
    {{ descriptionText }}
  </span>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Description',
  props: {
    descriptionText: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    ...mapGetters('configToken', ['getAvailableNetworks']),
    ...mapGetters('issuersInfo', ['issuerInfo']),
    blockchainType() {
      return this.issuerInfo.blockchain;
    },
    regex() {
      switch (this.blockchainType) {
        case 'algorand':
          return new RegExp('[0-9A-Z]{58}', 'g');
        case 'tezos':
          return new RegExp('(?:tz[123]|KT1)[a-zA-Z0-9]{33}', 'g');
        default:
          return new RegExp('(?:(?:0x)[a-fA-F0-9]{40})', 'g');
      }
    },
    splitDescription() {
      return this.descriptionText.split(this.regex);
    },
  },
  methods: {
    getWalletUrl(walletId) {
      const baseExplorerUrl = this.getAvailableNetworks.find(network => network.id === this.deploymentToken.provider)?.explorerUrl;
      return `${baseExplorerUrl }/address/${ walletId}`;
    },
    getDescriptionWallet(index = 0) {
      const text = this.descriptionText || '';
      const addressText = text.match(this.regex) || [];
      return addressText[index];
    },
  },
};
</script>
