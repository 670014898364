<template>
  <div>
    <b-card>
      <div class="row align-items-end">
        <div class="col-md-4">
          <b-button
            variant="primary"
            :disabled="!allPending"
            @click="$emit('onOpenTransactionsModal')"
          >
            {{ getButtonText }}
          </b-button>
        </div>
        <div class="col-md-4" />
        <div class="col-md-4">
          <label class="form-label">
            {{ $t('signatures.label.filter') }}
          </label>
          <b-select
            :value="selectedStatus"
            :options="getFilters()"
            @input="applyFilter"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { filter } from '../options';
import { i18nKeyListConvert } from '~/utilities/i18n-util';

export default {
  name: 'InvestorsBatchActions',
  props: {
    selectedPending: {
      type: Number,
      default: 0,
    },
    allPending: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      filter,
      selectedStatus: 'pending',
    };
  },
  computed: {
    getButtonText() {
      return !this.selectedPending
        ? this.$t('signatures.label.signAllTransaction')
        : this.$t('signatures.label.signTransaction', [this.selectedPending]);
    },
    acceptableFilters() {
      return this.filter.reduce((res, { key, value }) => {
        res[key.toLowerCase()] = value;
        return res;
      }, {});
    },
  },
  created() {
    const { status } = this.$route.query;
    if (this.acceptableFilters[status] !== undefined && status !== this.selectedStatus) {
      this.applyFilter(this.acceptableFilters[status]);
    } else {
      this.$router.replace({ name: 'signatures', query: { status: this.selectedStatus } });
    }
  },
  methods: {
    applyFilter(val) {
      this.$router.replace({ name: 'signatures', query: { status: val || 'all' } });
      this.selectedStatus = val;
      this.$emit('onApplyFilter', val);
    },
    getFilters() {
      return i18nKeyListConvert(this.filter, 'text');
    },
  },
};
</script>
