<template>
  <div>
    <div
      v-if="showSpinner"
      class="text-center my-2 minimum-height"
    >
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
    </div>
    <div
      v-else
    >
      <WalletComponent
        v-for="(provider, index) in providers"
        :key="index"
        :provider="provider.name"
        :icon="provider.icon"
        :rpc="rpc"
        :class="{'active': selectedProvider === provider.name}"
        @change="changeSelectedProvider"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import SecuritizeWalletConnect, { Wallet } from '@securitize/wallet-connect';

import WalletComponent from './wallet-component';
import Providers from './providers';

export default {
  name: 'CPSwim',
  components: { WalletComponent },
  props: {},
  data() {
    return {
      providers: Providers,
      selectedProvider: null,
      showSpinner: false,
      rpc: null,
      wallet: null,
    };
  },
  created() {
    this.showSpinner = true;
    this.selectedProvider = Providers[0].name;

    Promise.resolve(this.getRPC())
      .then((rpc) => {
        this.rpc = rpc;
        this.showSpinner = false;
      })
      .catch((err) => {
        this.showSpinner = false;
        this.CALL_ERROR_TOASTER(err);
      });
  },
  methods: {
    ...mapActions('signatures', [
      'getAbstractionLayerNetworkInfo',
      'getAbstractionLayerTokenProvider',
    ]),
    ...mapActions('walletConnectConfig', ['getWalletConnectProjectId']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    changeSelectedProvider(provider) {
      this.selectedProvider = provider;
    },
    async signAndSend({ unsignedTx }) {
      return this.wallet.sendTransaction(unsignedTx);
    },
    async sign({ unsignedTx, chainId, verifyingContractAddress, verifyingContractNonce }) {
      return this.wallet.signEip712Message(unsignedTx, chainId, verifyingContractAddress, verifyingContractNonce);
    },
    async getSignerAddress() {
      return this.wallet.getSelectedAddress();
    },
    async initWallet() {
      try {
        const { chainName, chainId, url } = await this.getRPC();

        const walletType = Wallet[this.selectedProvider];

        if (!walletType) throw new Error('Wallet type not found');

        const wallet = new SecuritizeWalletConnect()
            .setWallet(walletType)
            .setRpc({
              chainName,
              chainId,
              url,
            })
            .build();

        await wallet.connect();
        this.wallet = wallet;
      } catch (err) {
        this.CALL_ERROR_TOASTER(err);
        // Cleaning WC2 storage to allow switching connectors and showing the QR modal
        Object.keys(localStorage).forEach((item) => {
          if (RegExp('wc@2:*|WCM_*').test(item)) {
            localStorage.removeItem(item);
          }
        });
      }
    },
    async getRPC() {
      const { tokenId } = this.$route.params;
      const { data: providerInfo } = await this.getAbstractionLayerTokenProvider({ tokenId });
      const { data: networkInfo } = await this.getAbstractionLayerNetworkInfo({
        providerName: providerInfo.network,
      });
      return {
        chainName: networkInfo.name,
        chainId: networkInfo.chainId,
        url: networkInfo.rpc,
      };
    },
    async disconnect() {
      return this.wallet.disconnect();
    },
  },
};
</script>
<style scoped>
  .active {
    border: 1px solid #04bec4;
  }
  .--wcm-z-index {
    z-index: 9999;
  }
</style>
