<template>
  <div>
    <b-alert
      v-for="(error, index) in errors"
      :key="index"
      variant="danger"
      show
    >
      {{ error }}
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'CpErrorNotices',
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
