export default [
  {
    key: 'checkbox', label: ' ', tdClass: 'text-nowrap align-middle',
  },
  {
    key: 'id', i18nKey: 'signatures.label.table.id', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'type', i18nKey: 'signatures.label.table.type', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'owner', i18nKey: 'signatures.label.table.owner', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'description', i18nKey: 'signatures.label.table.description', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'signaturesRequired', i18nKey: 'signatures.label.table.signaturesRequired', tdClass: 'align-middle',
  },
  {
    key: 'status', i18nKey: 'signatures.label.table.status', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'date', i18nKey: 'signatures.label.table.date', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center',
  },
];
