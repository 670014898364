import MetamaskIcon from '@/assets/img/wallets/Metamask.svg';
import CoinBase from '@/assets/img/wallets/CoinBase.svg';
import WalletConnect from '@/assets/img/wallets/WalletConnect.svg';

const Providers = [
    {
        name: 'Metamask',
        icon: MetamaskIcon,
    }, {
        name: 'WalletConnect',
        icon: WalletConnect,
    }, {
        name: 'Coinbase',
        icon: CoinBase,
    },
];

export default Providers;
