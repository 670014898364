export default class SigningUtils {
    static getNetworkOptions(additionalData) {
        let options;
        // eslint-disable-next-line no-restricted-globals
        if (additionalData.chainId && !isNaN(additionalData.chainId)
            // eslint-disable-next-line no-restricted-globals
            && additionalData.networkId && !isNaN(additionalData.networkId)) {
            options = { chainId: additionalData.chainId, networkId: additionalData.networkId, txType: additionalData.txType };
        } else if (additionalData.txType === 'atomic_transaction_composer') {
            options = { networkType: additionalData.networkType, txType: additionalData.txType };
        } else if (additionalData.txType === 'atomic_transaction_composer_logic_sig') {
            options = { networkType: additionalData.networkType, txType: additionalData.txType, transactionSignatureTypes: additionalData.transactionSignatureTypes, logicSignatureBytecode: additionalData.logicSignatureBytecode };
        } else {
            const { networkType: chain, networkId: chainId } = additionalData;
            options = { chainId, chain };
        }
        return options;
    }
}
