<template>
  <div>
    <div
      v-if="showSpinner"
      class="text-center my-2 minimum-height"
    >
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
    </div>
    <div
      v-else
    >
      <Wallet
        v-for="(provider, index) in providers"
        :key="index"
        :provider="provider.name"
        :icon="provider.icon"
        :rpc="rpc"
        @wallet="processTransaction"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Wallet from '@/pages/_idIssuer/signatures/components/swim-components/wallet-component';
import Providers from './swim-components/providers';

export default {
  name: 'CPSwim',
  components: { Wallet },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      providers: Providers,
      rpc: null,
      showSpinner: true,
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
  },
  async created() {
    this.showSpinner = true;
    Promise.resolve(this.initializeComponent()).then(() => {
      this.showSpinner = false;
    });
  },
  methods: {
    ...mapActions('signatures', [
      'invokeDsProtocol',
      'getAbstractionLayerNetworkInfo',
      'getAbstractionLayerTokenProvider',
    ]),
    ...mapActions('configToken', ['sendTransactionHash']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    async initializeComponent() {
      const { tokenId } = this.$route.params;
      const { data: providerInfo } = await this.getAbstractionLayerTokenProvider({ tokenId });
      const { data: networkInfo } = await this.getAbstractionLayerNetworkInfo({
        providerName: providerInfo.network,
      });

      this.rpc = {
        chainName: networkInfo.name,
        chainId: networkInfo.chainId,
        url: networkInfo.rpc,
      };
    },
    async processTransaction(wallet) {
      await wallet.connect();
      const signerAddress = await wallet.getSelectedAddress();
      const promises = [];

      this.transactions.forEach(({ id, deploymentId }) => {
        promises.push(
          this.getTransactionData(id, signerAddress)
            .then(responseData => wallet.sendTransaction(responseData))
            .then(transactionProviderId => this.sendTransactionHash({
              deploymentId,
              signatureTxId: id,
              transactionProviderId,
            }))
            .catch(error => this.CALL_ERROR_TOASTER(error)),
        );
      });

      Promise.all(promises)
        .then(() => wallet.disconnect())
        .finally(() => {
          this.$emit('onSuccess');
        });
    },
    async getTransactionData(blockchainTransactionId, signerAddress) {
      const { idIssuer: issuerId, tokenId } = this.$route.params;
      const { data: responseData } = await this.invokeDsProtocol({
        issuerId,
        tokenId,
        blockchainTransactionId,
        signerAddress,
      });

      return responseData.transactionData;
    },
  },
};
</script>

<style scoped></style>
