export const txStatus = {
    styles: {
        sent: 'outline-secondary',
        pending: 'outline-secondary',
        success: 'outline-success',
        signed: 'outline-primary',
        mempool: 'outline-primary',
        failure: 'outline-danger',
        canceled: 'outline-danger',
        expired: 'outline-danger',
    },
    labels: {
        sent: 'signatures.status.txStatus.sent',
        pending: 'signatures.status.txStatus.pending',
        success: 'signatures.status.txStatus.success',
        signed: 'signatures.status.txStatus.signed',
        mempool: 'signatures.status.txStatus.mempool',
        failure: 'signatures.status.txStatus.failure',
        canceled: 'signatures.status.txStatus.canceled',
        expired: 'signatures.status.txStatus.expired',
    },
};

export const typeLabels = {
    IssueTokens: 'signatures.status.type.issueTokens',
    IssueTokensToTBE: 'signatures.status.type.issueTokensToTBE',
    RegisterWallet: 'signatures.status.type.registerWallet',
    HoldTrading: 'signatures.status.type.holdTrading',
    ReleaseTrading: 'signatures.status.type.releaseTrading',
    LockTokens: 'signatures.status.type.lockTokens',
    UnlockTokens: 'signatures.status.type.unlockTokens',
    BurnTokens: 'signatures.status.type.burnTokens',
    BurnTBETokens: 'signatures.status.type.burnTBETokens',
    ReleaseLock: 'signatures.status.type.releaseLock',
    UpdateComplianceRules: 'signatures.status.type.updateComplianceRules',
    UpdateRole: 'signatures.status.type.updateRole',
    UpdateCountryComplianceStatus: 'signatures.status.type.updateCountryComplianceStatus',
    InternalTBETransfer: 'signatures.status.type.internalTBETransfer',
    TransferTBE: 'signatures.status.type.transferTBE',
    CreateAssetAlgorand: 'signatures.status.type.createAssetAlgorand',
    ReallocateTokens: 'signatures.status.type.reallocateTokens',
    InvestorLock: 'signatures.status.type.investorLock',
    InvestorUnlock: 'signatures.status.type.investorUnlock',
    BulkIssueTokens: 'signatures.status.type.bulkIssueTokens',
    BulkBurnTokens: 'signatures.status.type.bulkBurnTokens',
    BulkRegister: 'signatures.status.type.bulkRegister',
};

export const filter = [
    { key: 'all', i18nKey: 'signatures.option.txStatus.all', value: null },
    { key: 'pending', i18nKey: 'signatures.option.txStatus.pending', value: 'pending' },
    { key: 'sent', i18nKey: 'signatures.option.txStatus.sent', value: 'sent' },
    { key: 'mempool', i18nKey: 'signatures.option.txStatus.mempool', value: 'mempool' },
    { key: 'success', i18nKey: 'signatures.option.txStatus.success', value: 'success' },
    { key: 'failure', i18nKey: 'signatures.option.txStatus.failure', value: 'failure' },
    { key: 'canceled', i18nKey: 'signatures.option.txStatus.canceled', value: 'canceled' },
    { key: 'expired', i18nKey: 'signatures.option.txStatus.expired', value: 'expired' },
];

export const signatureTypes = [
    {
        key: 'singleSignature',
        i18nKey: 'signatures.signTransactionModal.signatureTypes.singleSignature',
        value: 'singleSignature',
    },
    {
        key: 'multiSignature',
        i18nKey: 'signatures.signTransactionModal.signatureTypes.multiSignature',
        value: 'multiSignature',
    },
];
