<template>
  <div
    class="wallet-card"
    @click="select"
  >
    <div class="wallet-name">
      <h5>{{ provider }}</h5>
    </div>
    <img
      :src="icon"
      alt="Provider"
    >
  </div>
</template>

<script>

export default {
  name: 'WalletComponent',
  props: {
    icon: {
      type: String,
      default: '',
    },
    provider: {
      type: String,
      default: '',
    },
    rpc: {
      type: Object,
      default: null,
    },
  },
  methods: {
    select() {
      this.$emit('change', this.provider);
    },
  },
};
</script>

<style scoped>
  .wallet-card {
    display: flex;
    height: 50px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: 0.2s all;
    margin: 0 0 15px 0;
  }
  .wallet-card img {
    margin-left: auto;
    padding-right: 15px;
    width: 50px;
    height: 50px;
  }
  .wallet-card:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  }
  .wallet-name {
    padding: 15px;
    position: absolute;
  }
</style>
