<template>
  <cp-confirm-modal
    ref="viewTransaction"
    size="lg"
    :title="$t('signatures.viewTransactionModal.title', [currentItem.id])"
    ok-only
  >
    <template slot="slot-extended">
      <div class="card border-light mx-4 mt-4">
        <b-card>
          <strong>{{ $t('signatures.field.id') }}</strong>{{ currentItem.id }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.owner') }}</strong>{{ currentItem.operatorName || ' - ' }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.description') }}</strong><description :description-text="currentItem.description" />
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.type') }}</strong>{{ currentItem.type }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.status') }}</strong>{{ transactionInfo.status }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.date') }}</strong>{{ currentItem.date | dateFilter }}
        </b-card>
        <b-card>
          <strong>{{ $t('signatures.field.transaction') }}</strong>{{ transactionInfo.transaction }}
        </b-card>
        <div v-if="showEtherscanLink">
          <b-card>
            <strong>{{ $t('signatures.field.tx') }}</strong>{{ transactionInfo.txId || transactionInfo.transactionProviderId }}
          </b-card>
        </div>
      </div>
    </template>
  </cp-confirm-modal>
</template>

<script>
import Description from './description';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'ViewTransactionSignatures',
  components: {
    CpConfirmModal,
    Description,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    transactionInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showEtherscanLink() {
      const { status } = this.transactionInfo;
      return status === 'mempool' || status === 'sent' || status === 'success' || status === 'failure';
    },
  },
};
</script>
