<template>
  <div>
    <div
      v-if="showSpinner"
      class="text-center my-2 minimum-height"
    >
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner class="align-middle" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-items-center"
    >
      <cp-select
        v-model="selectedOperatorKey"
        :options="operatorKeys"
        placeholder
        name="operatorKeysSelect"
        class="mr-2"
      />
      <small>
        <i
          :title="$t('signatures.signTransactionModal.message.hsmMessage')"
          class="ion ion-ios-information-circle-outline mb-3"
        />
      </small>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'CPHsm',
  components: { CpSelect },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    operatorKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOperatorKey: null,
      showSpinner: false,
      unsignedTx: '',
    };
  },
  methods: {
    ...mapActions('signatures', [
      'invokeDsProtocol',
      'getOperatorTransactionSignature',
      'getOperatorMessageSignature',
      'getOperatorHSMKeys',
    ]),
    ...mapActions('blockchainMessages', ['createEIP712Message']),
    getSelectedOperatorKey() {
      return this.selectedOperatorKey;
    },
    async sign({ unsignedTx, options }) {
      return this
          .getOperatorTransactionSignature({ hsmAddress: this.selectedOperatorKey, unsignedTx: `0x${unsignedTx}`, options });
    },

    async signWithMultiSig({ unsignedTx, multiSigWalletAddress, multiSigiWalletNonce, chainId }) {
      const eip712Message = await this.createEIP712Message({
        contractName: 'multisigWallet',
        contractVersion: 1,
        verifyingContractAddress: multiSigWalletAddress,
        verifyingContractNonce: Number(multiSigiWalletNonce),
        transactionDataHash: `0x${unsignedTx}`,
        chainId,
      });

      const { data: signature } = await this.getOperatorMessageSignature({
        hsmAddress: this.selectedOperatorKey,
        unsignedMessage: eip712Message.data.unsignedMessage,
      });

      return {
        signedTransaction: `0x${unsignedTx}`,
        signature: {
          s: signature.sign.s,
          r: signature.sign.r,
          v: signature.sign.v,
        },
      };
    },
  },
};
</script>

<style scoped>

</style>
