<template>
  <cp-table
    v-if="!isInitialToken && !loading"
    ref="cpTable"
    without-search
    :filters="filters"
    :fields="fields"
    get-data-action="signatures/getTransactionSignaturesList"
    :url-params="urlParams"
    @onSort="$emit('onSort', $event)"
    @tableDataUpdated="clearSelection"
  >
    <template
      slot="HEAD_checkbox"
      slot-scope="{ rowData }"
    >
      <div
        v-if="rowData"
        class="custom-control custom-checkbox"
      >
        <input
          id="select-all-cb-0"
          v-model="allSelect"
          type="checkbox"
          class="custom-control-input"
          @click="onSelectAll"
        >
        <label
          for="select-all-cb-0"
          class="custom-control-label"
        />
      </div>
    </template>
    <template
      slot="checkbox"
      slot-scope="{ rowData }"
    >
      <b-form-checkbox
        v-if="!isSigned(rowData.item)"
        v-model="selectedEntity[rowData.item.id]"
        @change="selectEntity(rowData.item)"
      />
    </template>
    <template
      slot="type"
      slot-scope="{ rowData }"
    >
      <b-badge variant="outline-info">
        {{ $t(typeLabels[rowData.item.type]) }}
      </b-badge>
    </template>
    <template
      slot="owner"
      slot-scope="{ rowData }"
    >
      <description
        :description-text="rowData.item.operatorName"
      />
    </template>
    <template
      slot="description"
      slot-scope="{ rowData }"
    >
      <description :description-text="rowData.item.description" />
    </template>
    <template
      slot="status"
      slot-scope="{ rowData }"
    >
      <b-badge
        class="align-text-bottom ml-1"
        :variant="txStatus.styles[rowData.item.status]"
      >
        {{ $t(txStatus.labels[rowData.item.status]) }}
      </b-badge>
    </template>
    <template
      slot="date"
      slot-scope="{ rowData }"
    >
      <span>{{ rowData.item.date | dateFilter }}</span>
    </template>
    <template
      slot="signaturesRequired"
      slot-scope="{ rowData }"
    >
      <div v-if="isSigned(rowData.item) && rowData.item.signatures === 0">
        {{ `1 / ${rowData.item.threshold}` }}
      </div>
      <div v-else>
        {{ `${rowData.item.signatures} / ${rowData.item.threshold}` }}
      </div>
    </template>
    <template
      slot="actions"
      slot-scope="{ rowData }"
    >
      <b-dropdown
        right
        variant="default btn-xs icon-btn md-btn-flat hide-arrow"
      >
        <template slot="button-content">
          <i class="ion ion-ios-settings" />
        </template>
        <b-dropdown-item
          v-if="!isSigned(rowData.item) && notFailure(rowData.item)"
          @click="signTransaction(rowData.item)"
        >
          {{ $t('signatures.option.menu.sign') }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="viewTransaction(rowData.item)"
        >
          {{ $t('signatures.option.menu.view') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!isSigned(rowData.item)"
          @click="deleteTransaction(rowData.item)"
        >
          {{ $t('signatures.option.menu.remove') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!isSigned(rowData.item) && (rowData.item.signatures && rowData.item.signatures > 0)"
          @click="clearSignatures(rowData.item)"
        >
          {{ $t('signatures.option.menu.clearSignatures') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!isAlgorandNetwork && isSent(rowData.item)"
          @click="speedUpTransaction(rowData.item)"
        >
          {{ $t('signatures.option.menu.speedUp') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!isAlgorandNetwork && isSent(rowData.item)"
          @click="cancelTransaction(rowData.item)"
        >
          {{ $t('signatures.option.menu.cancelTransaction') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </cp-table>
  <div v-else>
    <b-card>
      <div class="row align-items-center mb-2">
        <div class="col-md-12 text-center">
          <b-spinner
            v-if="loading"
            class="align-middle"
          />
          <span v-else-if="isInitialToken">
            {{ $t('signatures.label.tokenInitialStateMessage') }}
          </span>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import CpTable from '~/components/shared/cp-table';
import fields from '../fields';
import { txStatus, typeLabels } from '../options';
import Description from './description';

export default {
  name: 'CpSignatureTable',
  components: {
    CpTable,
    Description,
  },
  props: {
    filters: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      fields,
      txStatus,
      typeLabels,
      selectedEntity: {},
      transactions: [],
      allSelect: false,
      loading: false,
      tableData: [],
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken']),
    urlParams() {
      const { idIssuer: issuerId, tokenId } = this.$route.params;

      return { issuerId, tokenId };
    },
    isSigned() {
      return ({ status }) => status === 'success' || status === 'signed' || status === 'sent' || status === 'mempool';
    },
    isSent() {
      return ({ status }) => status === 'signed' || status === 'sent' || status === 'mempool';
    },
    notFailure() {
      return ({ status }) => status !== 'failure' && status !== 'expired';
    },
    isAlgorandNetwork() {
      return this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand');
    },
    isInitialToken() {
      return this.deploymentToken && this.deploymentToken.status === 'initial';
    },
  },
  created() {
    this.updateTokenStatus();
  },
  methods: {
    ...mapActions('configToken', ['getTokenDeploymentBasicInfo', 'getAvailableNetworks']),
    clearSelection() {
      this.allSelect = false;
      this.transactions = [];
      Object.keys(this.selectedEntity).forEach((key) => {
        this.selectedEntity[key] = false;
      });
      this.$forceUpdate();
      this.$emit('onSelect', this.transactions);
    },
    selectEntity(item) {
      this.allSelect = false;
      if (item.status !== 'pending') {
        return;
      }
      const findResult = this.transactions.findIndex(el => el.id === item.id);

      if (findResult === -1) {
        this.transactions.push(item);
      } else {
        this.transactions.splice(findResult, 1);
      }
      this.$emit('onSelect', this.transactions);
    },
    onSelectAll() {
      this.getTableData();
      this.allSelect = !this.allSelect;
      this.tableData.forEach((elem) => {
        if (elem.status === 'pending') {
          this.$set(this.selectedEntity, elem.id, this.allSelect);
        }
      });
      this.transactions = this.allSelect
        ? this.tableData.filter(item => item.status === 'pending') : [];
      this.$emit('onSelect', this.transactions);
    },
    signTransaction(item) {
      this.transactions = [item];
      this.selectedEntity = {};
      this.$emit('onSign', item);
      this.$emit('onSelect', this.transactions);
    },
    speedUpTransaction(item) {
      this.transactions = [item];
      this.selectedEntity = {};
      this.$emit('onSpeedUp', item);
      this.$emit('onSelect', this.transactions);
    },
    cancelTransaction(item) {
      this.transactions = [item];
      this.selectedEntity = {};
      this.$emit('onCancelTransaction', item);
      this.$emit('onSelect', this.transactions);
    },
    viewTransaction(item) {
      this.$emit('onView', item);
    },
    deleteTransaction(item) {
      this.$emit('onDelete', item);
    },
    clearSignatures(item) {
      this.$emit('onClearSignature', item);
    },
    getTableData() {
      this.tableData = this.$refs.cpTable.getTableData();
    },
    updateTokenStatus() {
      this.loading = true;
      const { tokenId, idIssuer: issuerId } = this.$route.params;

      Promise.all([
        this.getTokenDeploymentBasicInfo({ tokenId, issuerId }),
        this.getAvailableNetworks(),
      ]).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
